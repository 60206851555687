<template>
  <v-main>
    <v-container>
      <h3 class="mb-5 mt-5">User Detail</h3>
      <section v-if="errored">
        <p>
          We're sorry, we're not able to retrieve this information at the
          moment, please try back later
        </p>
      </section>
      <div style="position:absolute;margin:0 auto;left:50%,right:50%;" v-if="loading">Loading...</div>
      <v-row>
        <h5>User Type: {{filterUserType(userData.user_type)}}</h5>
      </v-row>
      <v-row>
      <v-col>
          <label>Name</label>
          <hr/>
          <div class="name">{{userData.name}}</div>
      </v-col>
      <v-col>
          <label>Phone No</label>
          <hr/>
          <div class="name"><a href="">{{userData.phone}}</a></div>
      </v-col>
      <v-col>
          <label>Age</label>
          <hr/>
          <div class="name">{{userData.age}}</div>
      </v-col>
      <v-col>
          <label>Gender</label>
          <hr/>
          <div class="name">{{filterGender(userData.gender)}}</div>
      </v-col>
      <v-col>
          <label>Marital Status</label>
          <hr/>
          <div class="name">{{filterMaritalStatus(userData.marital_status)}}</div>
      </v-col>
      <v-col>
          <label>State/Region</label>
          <hr/>
          <div class="name">{{userData.state_region}}</div>
      </v-col>
      <v-col>
          <label>Register Date</label>
          <hr/>
          <div class="name">{{userData.createddate}}</div>
      </v-col>
    </v-row>

    <h3 class="mt-5 mb-5">Favorite List</h3>
    <v-row>
        <div>
          <ul v-for="(fav, i) in favoriteList" :key="i">
            <li>{{fav.title}}</li>
          </ul>
        </div>
    </v-row>
    </v-container>
  </v-main>
</template>

<script>
// @ is an alias to /src
// import { mapGetters } from "vuex";
import { FETCH_USER_DETAIL } from "../store/actions.type";
export default {
  name: "UserUpdate",
  data() {
    return {
      userData: [],
      info: null,
      loading: true,
      errored: false,
      favoriteList: [],
      currentUserID: null,
    };
  },
  computed: {
  },
  mounted() {
    this.currentUserID = localStorage.getItem("selectedUser");
    var payload = {"userID" : this.currentUserID};
    this.$store
      .dispatch(FETCH_USER_DETAIL,payload)
      .then((data) => {
        this.loading = false;
        this.userData = data.userData;
        this.favoriteList = data.favoriteList;
      })
      .catch((response) => {
        console.log(response);
        this.loading = false;
        this.errored = true;
      })
      .finally(() => {
        this.loading = false;
        this.errored = false;
      });
  },
  created() {},
  methods: {
    filterGender(gender) {
      var response = ""
      if(gender == "f"){
        response = "Female";
      }else if(gender == "m"){
        response = "Male";
      }else if(gender == "o"){
        response = "Other";
      }
      return response;
    },
    filterMaritalStatus(data) {
      var response = "";
      if(data == "single"){
        response = "Single";
      }else if(data == "married"){
        response = "Married";
      }
      return response;
    },
    filterUserType(data) {
      if(data == "N"){
        return "Normal User";
      }else if(data == "P") {
        return "Provider";
      }
    }
  },
};
</script>
